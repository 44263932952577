.Speech {
  position: fixed;
  bottom: 0px;
  left: 0px;
  height: 50px;
  width: 100%;
  background-color: #ffffff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  box-sizing: border-box;
  font-size: 25px;
}

.Button {
  padding: 10px;
  border: 1px;
  border-style: solid;
  border-color: black;
  border-radius: 10px
}

.Button:hover {
  border-color: green;
  background-color: lightgreen;
}

.Button:disabled {
  border-color: red;
  background-color: lightcoral;
  color: black;
  font-weight: bold;
}