.Controls {
  position: fixed;
  top: 50px;
  left: 0px;
  height: 50px;
  width: 100%;
  background-color: #cccccc;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  box-sizing: border-box;
  font-size: 25px;
}