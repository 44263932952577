.Playlists {
  position: absolute;
  top: 100px;
  left: 0px;
  background-color: #eeeeee;
  display: flex;
  flex-wrap: wrap;
  align-items: top;
  padding-top: 5px;
  z-index: -1;
}

.Playlist {
  border: 1px solid #999999;
  background-color: #eeeeee;
  font-size: 8pt;
  padding: 3px;
  width: 100px;
}