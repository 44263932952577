@font-face {
  font-family: 'icomoon';
  src: url('../fonts/icomoon.eot?3zblm8');
  src: url('../fonts/icomoon.eot?3zblm8#iefix') format('embedded-opentype'), url('../fonts/icomoon.ttf?3zblm8') format('truetype'), url('../fonts/icomoon.woff?3zblm8') format('woff'), url('../fonts/icomoon.svg?3zblm8#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-home:before {
  content: "\e900";
}

.icon-info:before {
  content: "\ea0c";
}

.icon-play2:before {
  content: "\ea15";
}

.icon-pause:before {
  content: "\ea16";
}

.icon-stop:before {
  content: "\ea17";
}

.icon-previous:before {
  content: "\ea18";
}

.icon-next:before {
  content: "\ea19";
}

.icon-volume-high:before {
  content: "\ea26";
}

.icon-volume-low:before {
  content: "\ea28";
}